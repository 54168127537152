import React from "react"
import PropTypes from "prop-types"

import styles from "./infoBoxGraphic.module.scss"
import { StaticQuery, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

const InfoBoxGraphic = ({ data }) => {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  }
  const random = getRandomInt(data.imgs.edges.length - 5);

  const pattern1 = <div className={styles.pattern1}>
    <div className={styles.line1} />
    <div className={styles.line2} />
    <div className={styles.line3} />
  </div>

  const pattern2 = <div className={styles.pattern2}>
    <span className={styles.line1} />
    <span className={styles.line2} />
    <div className={styles.line3} />
    <div className={styles.line4} />
  </div>

  const pattern3 = <div className={styles.pattern3}>
    <span className={styles.line1} />
    <span className={styles.line2} />
    <div className={styles.line3} />
    <div className={styles.line4} />
  </div>

  const pattern4 = <div className={styles.pattern4}>
    <div className={styles.line1} />
    <div className={styles.line2} />
    <div className={styles.line3} />
  </div>

  if (data.imgs.edges.length >= 3) {
    const imgs = [data.imgs.edges[random], data.imgs.edges[random + 1], data.imgs.edges[random + 2], data.imgs.edges[random + 3]];
    return <div className={styles.container}>
      <div className={styles.flexContainer}>
        {imgs.map((val, index) => {
          return <div key={index} className={styles.graphicContainer}>
            {index === 0 && pattern1}
            {index === 1 && pattern2}
            {index === 2 && pattern3}
            {index === 3 && pattern4}
            <div className={styles.imageContainer}>
              <GatsbyImage fluid={val.node.childImageSharp.fluid}>

              </GatsbyImage>
            </div>
          </div>
        })}
      </div>

    </div>
  }
  else {
    return <div className={styles.container}>asdfasdfasdfs</div>
  }
}

InfoBoxGraphic.propTypes = {

}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        imgs: allFile(filter: { relativePath: { glob: "testimonial/*" } }) {
          edges {
            node {
            childImageSharp {
              fluid(maxWidth: 60, maxHeight: 60) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
        }
      }
    `}
    render={data => <InfoBoxGraphic data={data} {...props} />}
  />
)
