import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styles from "./infoBox.module.scss"
import CutContainer from "./cutContainer"
import { purpleGradient } from "../services/constants"
import globalStyles from "../services/globalStyles.module.scss"
import InfoBoxGraphic from "./infoBoxGraphic";

const InfoBox = ({ children, data, classStyle, mobileImg, gradient, imgNumber, contentStyle }) => {
  let desktopImg = data.desktopImgPurple.childImageSharp.fluid;
  let mobileImgGatsby = data.mobileImg.childImageSharp.fluid;

  if (imgNumber === 3) {
    desktopImg = data.desktopImg3.childImageSharp.fluid;
  }

  return (
    <div className={[styles.card, classStyle].join(" ")}>
      {mobileImg && (
        <div>
          <CutContainer
            styleClass={styles.mobileImageContainer}
            style={{
              background: gradient ? gradient : purpleGradient,
              position: "relative",
            }}
          >
            {imgNumber === 2 ? <InfoBoxGraphic/> : <Img
              className={imgNumber === 2 ? styles.cardMobileImage2 : styles.cardMobileImage}
              fluid={mobileImgGatsby}
            />}
          </CutContainer>
        </div>
      )}
      <div className={contentStyle ? contentStyle : styles.cardContent}>{children}       {imgNumber !== 2 && <Img
        className={[imgNumber === 3 ? styles.cardImage3 : (imgNumber === 2 ? styles.cardImage2 : styles.cardImage), styles.bastardImage].join(' ')}
        fluid={desktopImg}
      />}</div>

        <div className={globalStyles.hideOnMobile}>{imgNumber === 2 && <InfoBoxGraphic/> }
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    desktopImg: PropTypes.shape({
      file: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            base64: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        desktopImgPurple: file(
          relativePath: { eq: "common/question_mark_purple.webp" }
        ) {
          childImageSharp {
            fluid(maxWidth: 426, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        desktopImg3: file(
          relativePath: { eq: "common/supportDesktop.webp" }
        ) {
          childImageSharp {
            fluid(maxWidth: 483, maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        mobileImg: file(relativePath: { eq: "common/question_mark.webp" }) {
          childImageSharp {
            fluid(maxWidth: 135, maxHeight: 186) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <InfoBox data={data} {...props} />}
  />
)
